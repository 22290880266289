<section id="services">
  <div class="view">
    <img alt class="bg" src="assets/images/placeholders/bg-jumbo.jpg" /><img
    alt
    class="bg"
    src="assets/images/placeholders/bg-jumbo.jpg"
  />
    <div class="content colors-e">
      <div class="container">
        <h2>Services</h2>
        <p class="header-details">
          <span class="highlight">We Are Here</span> For You
        </p>
        <div class="row">
          <div class="col-md-3 top-line">
            <h4>Environmental<br />Services</h4>
            <p class="highlight">
              ENMIN is dedicated to helping its clients' environmental compliance problems.
            </p>
            <p>
              ENMIN manages the entire environmental consenting process and supports
              project development and operation to ensure legal compliance and limit
              a project's environmental impact. Our Environmental Services cover:
            </p>
            <ul class="simple">
              <li>Environmental Assessment and Monitoring</li>
              <li>Site Investigation</li>
              <li>Risk Assessment</li>
              <li>Life Cycle Assessment</li>
              <li>Environmental Auditing</li>
              <li>Environmental Permiting</li>
              <li>Mine and Plant Decomissioning/Closure Planningt</li>
              <li>Ecology and Archeaological Studies</li>
              <li>Waste Management</li>
            </ul>
          </div>
          <div class="col-md-3 top-line">
            <h4>Geology<br />Services</h4>
            <p class="highlight">
              ENMIN provides guidance on site exploration and resource evaluation.
            </p>
            <p>
              ENMIN carries out field explorations, site investigations and resource evaluations
              that cover planning for mining projects. Our Geology Services cover:
            </p>
            <ul class="simple">
              <li>Geological Studies</li>
              <li>Sampling and Evaluation of Mineral Deposits</li>
              <li>Scoping Studies</li>
              <li>Exploration Project Planning and Management</li>
              <li>Ground Management Studies</li>
            </ul>
          </div>
          <div class="col-md-3 top-line">
            <h4>Mining<br />Services</h4>
            <p class="highlight">
              ENMIN offers technical services that cover the entire life cycle of a mine.
            </p>
            <p>
              ENMIN has the capacity to develop complete projects from concept to commissioning
              for green-field and expansions of existing projects. Our Mining Services cover:
            </p>
            <ul class="simple">
              <li>Conceptual and Economic Studies, including Plant and Mine Design</li>
              <li>Open Pit and Underground Mine Planning</li>
              <li>Pre-Feasibility and Bankable Feasibility Studies for New/Expansion Projects</li>
              <li>Site Advisory Services</li>
              <li>Flow Diagram Development</li>
              <li>Selection of Process and Mining Equipment</li>
            </ul>
          </div>
          <div class="col-md-3 top-line">
            <h4>Management<br />Services</h4>
            <p class="highlight">
              ENMIN offers advisory services that support all aspects of operating a mine.
            </p>
            <p>
              ENMIN provides guidance on operation and management of mining projects.
              Our Management Services cover:
            </p>
            <ul class="simple">
              <li>Project Schedule Development</li>
              <li>CAPEX and OPEX Studies</li>
              <li>Contract Negotiation</li>
              <li>Process and Productivity Audits</li>
              <li>Due Diligence for Acquisitions</li>
              <li>Maintenance and Reliability Audits</li>
              <li>Quality Assurance/ Quality Control Monitoring</li>
              <li>Operations Evaluations</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="view">
    <img alt class="bg" src="assets/images/placeholders/bg-jumbo.jpg" /><img
    alt
    class="bg"
    src="assets/images/placeholders/bg-jumbo.jpg"
  /><img alt class="bg" src="assets/images/placeholders/bg-jumbo.jpg" />
    <div class="content half-size colors-h">
      <div class="container">
        <div class="show-list slogan">
          <div class="show-item">We Are Engineers</div>
          <div class="show-item">We Are Miners</div>
          <div class="show-item">We Are Geologist</div>
          <div class="show-item">We Are Environmentalist</div>
          <div class="show-item">We Are Problem Solvers</div>
        </div>
        <div class="separator"></div>

      </div>
    </div>
  </div>
</section>
