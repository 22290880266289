<app-header></app-header>
<section id="home">
  <div class="view">
    <img alt class="bg" src="../../../../assets/images/home/geology.jpg" />
    <img alt class="bg" src="../../../../assets/images/home/test.jpg" />
    <img alt class="bg" src="../../../../assets/images/home/smallscale.jpg" />
    <img alt class="bg" src="../../../../assets/images/home/mimosa.jpg" />

    <div
      class="content home-alice full-size colors-a background-transparent"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-md-offset-1">
            <div
              class="text-right textillate heading hidden-xs hidden-sm"
              data-textillate-options="{loop:true, in:{effect:'flipInY', reverse:true}, out:{effect:'flipOutY', reverse:true}}"
            >
              <ul class="texts">
                <li>We Are Engineers</li>
                <li>We Are Miners</li>
                <li>We Are Geologists</li>
                <li>We Are Environmentalists</li>
                <li>We Are Problem Solvers</li>
              </ul>
            </div>
            <h1 class="heading text-right">Enmin</h1>
            <p class="text-right title">
              ENMIN is a resource and infrastructure development consulting firm based in Harare, Zimbabwe.
              ENMIN consultants have extensive experience in conducting environmental studies,
              technical valuation, due diligence, feasibility studies, mine and asset valuation
              and mine design for exploration and mining companies.

            </p>
            <p class="text-right">
              <a href="#about" class="button background-60-d heading-d"
              >About Us</a
              ><a href="#work" class="button background-60-f heading-f"
            >Our Work</a
            >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-about></app-about>
<app-people></app-people>
<app-services></app-services>
<app-portfolio></app-portfolio>
<app-contact></app-contact>
<app-footer></app-footer>
