import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
    formData!: FormGroup;
   // private submissionForm: AngularFirestoreCollection<any>;
    submitting = false;
    submitted = false;
    constructor(private builder: FormBuilder) {
      this.createForm();
    }
    ngOnInit() {}

    createForm() {
      this.formData = this.builder.group({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required , Validators.email]),
        message: new FormControl('', [Validators.required])
      });
    }

    onSubmit(formData: any) {
      console.log(formData);
      this.formData.reset();
      alert('Thank you for contacting us, your message has gone through')
      // this.submissionForm.add(formData).then(res => {
      //   console.log(res);
      //   this.submitted = true;
      // }).catch(err => console.log(err)
      // ).finally(() => {
      //   this.submitting = false;
      // });
    }
  }



