import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header-component/header.component';
import { FooterComponent } from './footer-component/footer.component';
import { HomeComponent } from './home/home.component';
import {ContactModule} from '../contact/contact.module';
import {ServicesModule} from '../services/services.module';
import {PortfolioModule} from '../portfolio/portfolio.module';
import {PeopleModule} from '../people/people.module';
import {AboutModule} from '../about/about.module';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomeComponent
  ],
  exports: [
    HomeComponent
  ],
  imports: [
    CommonModule, ContactModule, ServicesModule, PortfolioModule, AboutModule, PeopleModule
  ]
})
export class HomeModule { }
