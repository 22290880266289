<section id="contact">
  <div class="view">
    <img alt class="bg" src="assets/images/contact/contact-form-image-0.jpg"/><img
    alt
    class="bg"
    src="assets/images/contact/contact-form-image-1.jpg"
  /><img alt class="bg" src="assets/images/contact/contact-form-image-0.jpg"/>
    <div class="content full-size colors-h">
      <div class="container">
        <h2>Contact</h2>
        <p class="header-details">Keep In Touch</p>
        <div class="row">

            <div
              class="position-center scroll-in-animation"
              data-animation="fadeInLeft"
            >
              <p class="big-font uppercase">
                Tel: <strong class="highlight">+263 24 250 0053</strong>
              </p>
              <p class="big-font uppercase">
                100 Seke Road
                <br/>
                Hatfield
                <br/>
                Harare, Zimbabwe.
              </p>
              <p class="big-font">
                <b>
                  <a href="mailto:info@enminconsulting.com"
                  >info@enminconsulting.com</a
                  >
                </b>
              </p>
              <p class="big-font">
                <a target="_blank" href="https://linkedin.com"
                ><span class="fa-stack"
                ><i class="fa fa-circle fa-stack-2x heading"></i
                ><i
                  class="fa fa-linkedin fa-stack-1x text-background"
                ></i></span
                ></a>
              </p>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
