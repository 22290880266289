
<div class="page-border bottom colors-e background-solid">
  <a href="#top" class="hover-effect"
  >To <span class="highlight">Top</span></a
  >
</div>
<div class="page-border left colors-e background-solid">
  <ul>
    <li>
      <a href="https://linkedin.com/" target="_blank"
      ><i class="fa fa-linkedin"></i
      ></a>
    </li>
  </ul>
</div>
<!-- BEGIN: Top menu -->
<div class="page-border right colors-e background-solid"></div>

<nav
  class="navbar navbar-default navbar-fixed-top page-transition colors-e background-solid"
  role="navigation"
  id="top-nav"
>
  <div class="container">
    <div class="navbar-header">

      <a
        class="menu-toggle navbar-toggle"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        href="#"
      ><span></span
      ></a>
      <a class="navbar-brand" href=""
      ><span class="highlight">Enmin</span> Consulting</a
      >
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li><a href="#home" class="hover-effect">Home</a></li>
        <li><a href="#about" class="hover-effect">About</a></li>
        <li><a href="#team" class="hover-effect">Team</a></li>
        <li><a href="#services" class="hover-effect">Services</a></li>
        <li><a href="#contact" class="hover-effect">Contact</a></li>
      </ul>
    </div>
  </div>
</nav>
<!-- END: Top menu -->
<ul id="dot-scroll" class="colors-e background-solid"></ul>
<div
  class="overlay-window gallery-overlay colors-f background-95-f"
  data-overlay-zoom="#work .content">
  <div class="overlay-control background-85-d">
    <a class="previos" href="#"></a>
    <a class="next" href="#"></a>
    <a class="cross" href="#"></a>
  </div>
  <div class="overlay-view"></div>
  <ul class="loader">
    <li class="background-100-d"></li>
    <li class="background-100-d"></li>
    <li class="background-100-d"></li>
  </ul>
</div>


<div class="gate colors-e background-solid">
  <div class="gate-bar background-highlight-e"></div>
  <ul class="loader">
    <li class="background-100-g"></li>
    <li class="background-90-c"></li>
    <li class="background-100-f"></li>
  </ul>
</div>
