<section id="team">
  <div class="view">
    <img alt class="bg" src="assets/images/placeholders/team.jpg" /><img
    alt
    class="bg"
    src="assets/images/placeholders/team-summit.jpg"
  />
    <div class="content colors-e">
      <div class="container">
        <h2>Team</h2>
        <p class="header-details">
          <span class="highlight">Who </span>We Are
        </p>
        <p class="lead">
          ENMIN's team of specialists includes <strong class="highlight">project managers,
          construction supervisors, design engineers, and environmentalists</strong> from
          all relevant disciplines including:  mining, metallurgical, process engineering
          and environmental studies.
        </p>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-6">
            <div class="hover-overlay">
              <img
                alt="Farai Chifetete"
                src="assets/images/our-team/mining-engineer.jpg"
                title="Farai Chifetete"
                class="fluid-width"
              />
              <div class="overlay background-90-b">
                <div>
                  <p class="text-center text-uppercase heading-b">
                    Farai is a Mining Engineer with more than 30 years experience
                    in mining, project management and environmental consultancy.
                  </p>
                  <div class="separator-small"></div>
                  <p class="text-center">
                    <a target="_blank" href="https://www.linkedin.com/in/farai-bernard-chifetete-42a12792/"
                    ><i class="fa fa-linkedin heading-b"></i
                    ></a>

                  </p>
                </div>
              </div>
            </div>
            <div class="caption">
              <p>
                <span class="title">Farai Chifetete</span>
                <br />
                <span class="highlight">Mining, Environment &amp; Project Management Specialist</span>
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-6">
            <div class="hover-overlay">
              <img
                alt="Michael Gumbo"
                src="assets/images/our-team/geologist.jpg"
                title="Michael Gumbo"
                class="fluid-width"
              />
              <div class="overlay background-90-b">
                <div>
                  <p class="text-center text-uppercase heading-b">
                    Mike is a Geologist with more than 30 years experience
                    in all aspects of exploration management and mineral project
                    evaluation, mine operations management with major mining
                    corporations in Zimbabwe.
                  </p>
                  <div class="separator-small"></div>
                  <p class="text-center">
                    <a target="_blank" href="https://www.linkedin.com/in/michael-gumbo-16a331a4/"
                    ><i class="fa fa-linkedin heading-b"></i
                    ></a>
                  </p>
                </div>
              </div>
            </div>
            <div class="caption">
              <p>
                <span class="title">Michael Gumbo</span>
                <br />
                <span class="highlight">Geology Specialist</span>
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-6">
            <div class="hover-overlay">
              <img
                alt="Andrew Pahwaringira"
                src="assets/images/our-team/rocks.jpg"
                title="Andrew Pahwaringira"
                class="fluid-width"
              />
              <div class="overlay background-90-b">
                <div>
                  <p class="text-center text-uppercase heading-b">
                    Andrew is a Geologist with 30 years experience in exploration and onsite
                    mineral resources management, development and operations.
                  </p>
                  <div class="separator-small"></div>
                  <p class="text-center">
                    <a target="_blank" href="https://www.linkedin.com/in/andrew-pahwaringira-475b5532/"
                    ><i class="fa fa-linkedin heading-b"></i
                    ></a>
                  </p>
                </div>
              </div>
            </div>
            <div class="caption">
              <p>
                <span class="title">Andrew Pahwaringira</span>
                <br />
                <span class="highlight">Geology Specialist</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="view" id="who-we-are">
    <div class="content pane">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 stretch-height pane">
            <div class="view fluid-height">
              <img
                alt
                class="bg"
                src="assets/images/placeholders/ruschrome.jpg"
              />
              <div class="content incut colors-d background-transparent">
                <div class="position-middle-center text-center">
                  <a class="button background-lite-c heading-c" href="#work"
                  >View Our Work</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 stretch-height pane">
            <div class="view fluid-height">
              <div class="content incut colors-e background-solid">
                <div class="position-middle-center">
                  <h3 class="text-left">
                    <span class="highlight">Who</span> We Are
                  </h3>
                  <p class="subtitle highlight">
                    <strong
                    >Enmin's team of specialists includes project managers, design engineers, and environmentalists from all relevant disciplines including:
                      mining, metallurgical, and process engineering and environmental studies.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
