<section id="skills">
  <div class="view">
    <div class="content colors-e background-solid">
      <div class="container">
        <h2>Our <span class="highlight">Work</span></h2>
        <p class="header-details"><span class="highlight">Our Recent</span> Projects</p>
        <p class="lead">Since 2013, ENMIN has participated in an array of projects in the mining and environment space for diverse players in the market.</p>
        <div class="row">
          <div class="col-md-6">
            <ul class="widget-tabs nav nav-tabs background-lite-e">
              <li class="active"><a href="#geology" data-toggle="tab">Geology</a></li>
              <li class=""><a href="#mining" data-toggle="tab">Mining</a></li>
              <li class=""><a href="#environment" data-toggle="tab">Environment</a></li>
              <li class=""><a href="#civil" data-toggle="tab">Civil and Infrastructure</a></li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade active in" id="geology">
                <ul>
                  <li>Mt. Hampden Quarry Preliminary Assessment and Resource Valuation</li>
                  <li>Dodge Barite Mine Resource Validation</li>
                  <li>Zimbabwe Lithium Deposits: Desktop Study</li>
                  <li>Zimbabwe Coal Bed Methane Deposits: Desktop Study</li>
                  <li>Preliminary Assessment of the Hanga Gold Mine Project</li>
                </ul>
              </div>
              <div class="tab-pane fade" id="mining">
                <ul>
                  <li>Platinum Mining Underground Blasting Practice Review</li>
                  <li>Preliminary and Technical Assessment of the Giles Gold Mine, Mazowe</li>
                  <li>Preliminary and Technical Assessment of the JR Gold Mine</li>
                  <li>Darwendale Platinum Mine Trial Pit Design</li>
                  <li>Mining Standards Development for Gold Mining Group</li>
                </ul>
              </div>
              <div class="tab-pane fade" id="environment">
                <ul>
                  <li>Environmental Assessment of the Dodge Barite Mine Project</li>
                  <li>Mine Closure Plan  for Shamva Gold Mine</li>
                  <li>Mine Closure Plan for How Gold Mine</li>
                </ul>
              </div>
              <div class="tab-pane fade" id="civil">
                <ul>
                  <li>Scope of Works for Emulsion Explosives Plant</li>
                  <li>Preliminary Assessment of Site Infrastructure Layout for Mining Detonator Assembly Plant</li>
                </ul>

              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="skillbars">
              <div class="skillbar clearfix background-d" data-percent="50%">
                <div class="skillbar-title background-b heading-b"><span>Geology</span></div>
                <div class="skillbar-bar background-b"></div>
                <div class="skill-bar-percent heading-d">5</div>
              </div>
              <div class="skillbar clearfix background-d" data-percent="50%">
                <div class="skillbar-title background-c heading-c"><span>Mining</span></div>
                <div class="skillbar-bar background-c"></div>
                <div class="skill-bar-percent heading-d">5</div>
              </div>
              <div class="skillbar clearfix background-d" data-percent="30%">
                <div class="skillbar-title background-b heading-b"><span>Environmental</span></div>
                <div class="skillbar-bar background-b"></div>
                <div class="skill-bar-percent heading-d">3</div>
              </div>
              <div class="skillbar clearfix background-d" data-percent="20%">
                <div class="skillbar-title background-c heading-c"><span>Civil</span></div>
                <div class="skillbar-bar background-c"></div>
                <div class="skill-bar-percent heading-d">2</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
