<section id="about">
  <div class="view">
    <img alt class="bg" src="assets/images/placeholders/1920x1200-0.jpg" /><img
    alt
    class="bg"
    src="assets/images/placeholders/1920x1200-1.jpg"
  />
    <div class="content colors-e">
      <div class="container">
        <h2>About</h2>
        <p class="header-details">
          <span class="highlight">We Develop</span> Mining Projects
        </p>
        <p class="lead">
          ENMIN Consulting was founded in March 2013 to provide technical
          expertise in the development of
          <span class="highlight">mining and infrastructure projects.</span> The consultancy is wholly owned by its employees who are fulltime mining, infrastructure and environmental professionals. We
          maintain good contacts with associates both locally and abroad,who
          specialize in mining, civil and environmental consultancy
          issues. Enmin is well positioned to field specially selected teams on
          any assignment, whose skills are tailor-made to suit the needs of
          the project.
        </p>
        <div class="row">
          <div class="col-md-4">
            <div class="col-icon">
              <i class="li_bubble"></i>
            </div>
            <div class="col-content">
              <h4>Vision</h4>
              <p>
                Our vision is to be a leading and reliable partner for our customers in our areas of specialization,
                providing services in a manner that will contribute to development that is economically,
                environmentally and socially sustainable.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-icon">
              <i class="li_stack"></i>
            </div>
            <div class="col-content">
              <h4><span class="highlight">Mission</span></h4>
              <p>
                ENMIN's business is the provision of expertise to developers of infrastructure and mining projects.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-icon">
              <i class="li_note"></i>
            </div>
            <div class="col-content">
              <h4>Values</h4>

              <ul class="simple">
                <li>Honesty</li>
                <li>Integrity</li>
                <li>Transparency</li>
                <li>Teamwork</li>
                <li>Independence</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
